import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import TermlyPolicy from "../components/TermlyPolicy";

const TermsOfUse = () => {
  return (
    <Layout>
      <Seo title="Terms of Use" />

      <div className="container mb-5">
        <div className="col-12">
          <article className="row flex-column-reverse flex-md-row">
            <div className="col-md-8 offset-md-2">
              <TermlyPolicy policyId="d3373662-cbbb-49fb-b043-a506598b5a13" />
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
